.app {
  text-align: center;
  background-color: #282c34;
  display: flex;
  color: white;
  height: 100vh;
}

.logo {
  max-height: 60%;
}
.logo img {
  height: 100%;
  max-width: 100%;
}

.sound-area {
  position: fixed;
  left: 5px;
  top: 5px;
}

.question-area {
  display: flex;
  flex-direction: column;
  flex-basis: 70%;
  font-size: 3vh;
  height: 100%;
}

.money-area {
  flex-basis: 30%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.money-tree {
  flex-basis: 80%;
  justify-content: flex-start;
  display: flex;
  flex-direction: column-reverse;
  margin: 5px;
  font-size: 2.5vh;
}

.money-diamond-wrapper {
  min-height: 10%;
}

.lifelines {
  display: flex;
  justify-content: space-around;
  flex-basis: 20%;
  align-items: center;
  font-size: 4vh;
}

.lifeline {
  flex-basis: 25%;
  border: 1px solid white;
  border-radius: 50%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.lifeline.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  border: 1px solid red;
}

.lifelines path {
  color: white;
  fill: white;
}

.fiftyfifty {
  font-size: 3vh;
}

.logo {
  border-right: 1px solid white;
}

.question {
  min-height: 15%;
  padding: 5px;
  border-right: 1px solid white;
}

.answers {
  min-height: 15%;
  margin-bottom: 5%;
  padding: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-right: 1px solid white;
}

.answer-diamond-wrapper {
  flex-basis: 50%;
  max-height: 50%;
}

.animate-in {
  animation-duration: 1s;
  animation-name: animate-fade;
  animation-delay: 0.1s;
  animation-fill-mode: backwards;
}

@keyframes animate-fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.hidden {
  opacity: 0;
}