.diamond {
  position: relative;
  text-align: center;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.diamond svg {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  stroke-width: 0.5px;
}

.diamond-text {
  z-index: 1;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10%;
}

.diamond path {
  fill: rgb(8, 60, 136);
  stroke: white;
  -webkit-filter: url(#dropshadow);
  filter: url(#dropshadow);
}

.diamond path.right {
  fill: rgb(18, 185, 18);
}

.diamond path.wrong {
  fill: rgb(145, 1, 1);
}

.diamond path.current {
  stroke-width: 3px;
  fill: rgb(15, 32, 58);
}